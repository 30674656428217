<template>
	<div class="is-inline-flex">
		<!-- expand collapse buttons for groups-->
		<ws-button
			:tooltip="finalExpandTooltip"
			data-testid="expand-button"
			is-primary
			is-outlined
			@click="emits('expandedRows')"
		>
			<template #icon>
				<ws-icon icon="up-right-and-down-left-from-center" />
			</template>
		</ws-button>
		<ws-button
			:tooltip="finalCollapseTooltip"
			data-testid="collapse-button"
			class="ml-1"
			is-primary
			is-outlined
			@click="emits('collapsedRows')"
		>
			<template #icon>
				<ws-icon icon="down-left-and-up-right-to-center" />
			</template>
		</ws-button>
	</div>
</template>

<script setup>
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const emits = defineEmits(["expandedRows", "collapsedRows"]);
const props = defineProps({
	collapseTooltip: {
		type: String,
		default: null
	},
	expandTooltip: {
		type: String,
		default: null
	}
});

const { t: $t } = useI18n();

const finalCollapseTooltip = computed(() => {
	return (
		props.collapseTooltip ||
		$t("components.buttons-expand-collapse.collapse")
	);
});

const finalExpandTooltip = computed(() => {
	return (
		props.expandTooltip || $t("components.buttons-expand-collapse.expand")
	);
});
</script>
